import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@eclick/core';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'kodfeltoltes',
    loadChildren: () => import('./pages/upload-page/upload-page.module').then(m => m.UploadPageModule),
      canActivate: [AuthGuard],
      data: {
        redirectUrl: '/'
      }
  },
  {
    path: 'gyik',
    loadChildren: () => import('./pages/faq-page/faq-page.module').then(m => m.FaqPageModule)
  },
  {
    path: 'nyertesek',
    loadChildren: () => import('./pages/winners-page/winners-page.module').then(m => m.WinnersPageModule)
  },
  {
    path: 'promocios-termekek',
    loadChildren: () => import('./pages/products-page/products-page.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'kapcsolat',
    loadChildren: () => import('./pages/contact-page/contact-page.module').then(m => m.ContactPageModule)
  },
  {
    path: 'feltoltott-kodjaim',
    loadChildren: () => import('./pages/my-codes-page/my-codes-page.module').then(m => m.MyCodesPageModule),
    canActivate: [AuthGuard],
    data: {
      redirectUrl: '/'
    }
  },
  {
    path: 'elfelejtett-jelszo',
    loadChildren: () => import('./pages/forgotten-page/forgotten-page.module').then(m => m.ForgottenPageModule)
  },
  {
    path: 'uj-jelszo/:token',
    loadChildren: () => import('./pages/new-password-page/new-password-page.module').then(m => m.NewPasswordPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms-page/terms-page.module').then(m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy-page/privacy-page.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'cookie',
    loadChildren: () => import('./pages/cookie-page/cookie-page.module').then(m => m.CookiePageModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('./pages/imprint-page/imprint-page.module').then(m => m.ImprintPageModule)
  },
  {
    path: 'rules',
    loadChildren: () => import('./pages/rules/rules.module').then(m => m.RulesModule)
  },
  {
    path: 'stream',
    loadChildren: () => import('./pages/stream-page/stream-page.module').then(m => m.StreamPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
