import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../../core/services/documents.service';
import { Documents } from '../../../core/definitions/entities/documents';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  documents: Documents;

  constructor(private documentsService: DocumentsService) {
  }

  ngOnInit(): void {
    this.documents = this.documentsService.getDocuments();
  }

}
