import { Injectable } from '@angular/core';
import { Shops } from '../definitions/entities/shops';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  activeShops = [];

  constructor() {
  }

  setShops(shops) {
    for (const index of shops.keys()) {
      // console.log(shops[index]);
      const shopInstance: Shops = {
        id: 0,
        name: ''
      };
      shopInstance.id = shops[index].id;
      shopInstance.name = shops[index].name;
      this.activeShops.push(shopInstance);
    }
  }

  getShops() {
    return this.activeShops;
  }

}
