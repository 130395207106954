import { Injectable } from '@angular/core';
import { Faq } from '../definitions/entities/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  faqs: Faq[];


  constructor() { }

  setFaqs(faqs: Faq[]) {
    this.faqs = faqs;
  }

  getFaqs() {
    return this.faqs;
  }
}
