import { Injectable } from '@angular/core';
import { UploadResponse } from '../definitions/entities/upload-response';

@Injectable({
  providedIn: 'root'
})
export class UploadResponseService {

  uploadResponse: UploadResponse;

  constructor() {
  }

  setUploadData(uploadData: UploadResponse) {
    this.uploadResponse = uploadData;
  }

  getUploadData(): UploadResponse {
    return this.uploadResponse;
  }

  clearData() {
    return this.uploadResponse = null;
}
}
