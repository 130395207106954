import { Injectable } from '@angular/core';
import { Documents } from '../definitions/entities/documents';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  documents: Documents;

  constructor(private sanitizer: DomSanitizer) { }

  setDocuments(documents: Documents) {
    this.documents = documents;
  }

  getDocuments() {
    return this.documents;
  }

  sanitize(document) {
    return this.sanitizer.bypassSecurityTrustHtml(document);
  }
}
