import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {

  @Input() appMaxLength = 0;

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event'])
  onInputChange(event) {
    const initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.substring(0, this.appMaxLength);
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
