<div class="prizes-wrapper" id="prizes">


  <div class="title-wrapper title-wrapper-prizes  container">
    <h1 class="title green-bg">Nyeremények</h1>
  </div>
  <!--  <div class="container">-->

  <div class="prizes container">
    <div class="products">
      <img src="/assets/img/home/prizes/prize_places_50.png" alt="">
    </div>
    <div class="text-wrapper">
      <h2>Személyes gasztro workshop Krausz Gáborral - 50 résztvevővel</h2>
      <p>Lehet, hogy épp te leszel az 50 szerencsés nyertes egyike, aki találkozhat és együtt főzhet Krausz Gáborral. A
        sztárséf 2024. november 24-én a Chefparade főzőiskolában (1023 Budapest, Bécsi út 27.) nem csak bemutatja
        ízletes villámfogásait, de közösen készíti el azokat a résztvevőkkel. Vár az ízek iskolája, hogy vendégeid előtt
        majd te is sztárséfként tündökölhess!
      </p>
      <div class="loc-time">
        <div>
          <strong>
            Időpont:
          </strong> <br>
          2024. november 24. 10:00-14:00 <br><br>
        </div>
        <div>
          <strong>Helyszín:</strong> <br>
          Chefparade Főzőiskola <br>
          1023 Budapest, Bécsi út 27.
        </div>
      </div>
    </div>

  </div>

  <div class="prizes container reversed">
    <div class="products">
      <img src="/assets/img/home/prizes/prize_places_100_home.png" alt="">
    </div>
    <div class="text-wrapper">
      <h2>Online gasztro workshop Krausz Gáborral - 100 résztvevővel</h2>
      <p>Kereken 100 szerencsés nyertes élő közvetetésen keresztül kapcsolódhat be Krausz Gábor 2024. november 24-ei
        gasztro workshop-jára. A konyhád sem kell elhagynod, hogy együtt főzhess a sztárséffel!
        <br>
        Az e-recepteket már az esemény előtt biztosítjuk számodra.</p>
      <div class="loc-time">
        <div>
          <strong>
            Időpont:
          </strong> <br>
          2024. november 24. 10:00-14:00 <br><br>
        </div>
        <div>
          <strong>Helyszín:</strong> <br>
          Bárhol, ahol az online <br> közvetítést követni tudod
        </div>
      </div>
    </div>

  </div>
  <div class="info container">
    <p>A nyeremények képe illusztráció.</p>
  </div>
  <!--  </div>-->
</div>
\\
