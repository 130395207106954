import { Component, OnInit } from '@angular/core';
import { SyossUserService } from '../../../core/services/syoss-user.service';
import { UploadResponseService } from '../../../core/services/upload-response.service';
import { UploadResponse } from '../../../core/definitions/entities/upload-response';

@Component({
  selector: 'app-my-codes',
  templateUrl: './my-codes.component.html',
  styleUrls: ['./my-codes.component.scss']
})
export class MyCodesComponent implements OnInit {

  codes: any[] = [];
  uploadResponse: UploadResponse;

  constructor(private user: SyossUserService, private uploadResponseService: UploadResponseService) {
  }

  ngOnInit(): void {
    this.user.getCodes().subscribe((codes) => {
      this.codes = codes;
      console.log(this.codes);
    });

    this.uploadResponse = this.uploadResponseService.getUploadData();
  }

  close() {
    this.uploadResponse = this.uploadResponseService.clearData();
  }

}
