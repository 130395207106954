import { Injectable } from '@angular/core';
import { ApiService } from '@eclick/core';
import { Winners } from '../definitions/entities/winners';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WinnersService {

  winners: any;

  constructor(protected api: ApiService) {
  }

  setWinners(winners: Winners) {
    this.winners = winners;
  }

  getWinners() {
    return new Observable(subscriber => {
      this.api.get('winners').subscribe({
        next: value => {
          this.winners = value.data;
          subscriber.next(this.winners);
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          return this.winners;
        }
      });
    });

  }
}
