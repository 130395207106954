<header [class.opened]="opened" id="header">
  <button class="hamburger hamburger--collapse" type="button" [class.is-active]="opened" (click)="toggle()">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
  </button>
  <!--  <div class="container">-->
  <nav>
    <div class="wrapper-left">
      <!--        <div class="placeholder">-->
      <!--          -->
      <!--        </div>-->
      <div class="logo logo-mobile">
        <a [routerLink]="['/']"><img src="/assets/img/shared/somat_logo.png" class="img-fluid"></a>
      </div>
      <div class="menu">
        <ul class="container">
          <div class="logo logo-desktop">
            <a [routerLink]="['/']"><img src="/assets/img/shared/somat_logo.png" class="img-fluid"></a>
          </div>
          <a href="#" [routerLink]="['/kodfeltoltes']" [routerLinkActive]="'active'" (click)="toggle()"
             *ngIf="loggedIn">
            <li>Kódfeltöltés</li>
          </a>
          <a href="#" [routerLink]="['']" [ngxScrollTo]="'#prizes'" [ngxScrollToOffset]="-150"
             [routerLinkActive]="'active'" (click)="toggle()">
            <li>Nyeremények</li>
          </a>
          <a href="#" [routerLink]="['/nyertesek']" [routerLinkActive]="'active'" (click)="toggle()">
            <li>Nyertesek</li>
          </a>
          <a href="#" [routerLink]="['/gyik']" [routerLinkActive]="'active'" (click)="toggle()">
            <li>GYIK</li>
          </a>
          <a href="#" [routerLink]="['']" *ngIf="!loggedIn" [ngxScrollTo]="'#login'" [ngxScrollToOffset]="-150"
             [routerLinkActive]="'active'"
             (click)="toggle()">
            <li>Belépés</li>
          </a>
          <a href="#" [routerLink]="['']" *ngIf="!loggedIn" [ngxScrollTo]="'#registration'" [ngxScrollToOffset]="-150"
             [routerLinkActive]="'active'" (click)="toggle()">
            <li>Regisztráció</li>
          </a>
          <a href="#" *ngIf="loggedIn" [routerLink]="['/feltoltott-kodjaim']"
             [routerLinkActive]="'active'"
             (click)="toggle()">
            <li>Feltöltött kódjaim</li>
          </a>
          <a href="#" [routerLink]="['']" *ngIf="loggedIn"
             [routerLinkActive]="'active'"
             (click)="toggle(); logout()">
            <li>Kijelentkezés</li>
          </a>

        </ul>
      </div>
    </div>
  </nav>
  <!--  </div>-->
</header>
