import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { PrizesComponent } from './components/prizes/prizes.component';
import { MyCodesComponent } from './components/my-codes/my-codes.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { MaxLengthDirective } from './directives/max-length.directive';
import { HeroComponent } from './components/hero/hero.component';
import { PackshotComponent } from './components/packshot/packshot.component';


@NgModule({
  declarations: [
    HeroComponent,
    HeaderComponent,
    FooterComponent,
    PrizesComponent,
    MyCodesComponent,
    OnlyNumberDirective,
    MaxLengthDirective,
    PackshotComponent,
  ],
  exports: [
    HeroComponent,
    HeaderComponent,
    FooterComponent,
    PrizesComponent,
    MyCodesComponent,
    PackshotComponent,
    OnlyNumberDirective,
    MaxLengthDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ScrollToModule.forRoot()
  ]
})
export class SharedModule {
}

