import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '@eclick/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  opened = false;
  loggedIn = false;


  constructor(private auth: AuthenticationService) {
    this.loggedIn = this.auth.isLoggedIn();
    this.auth.onAuthChange().subscribe(() => {
      this.loggedIn = this.auth.isLoggedIn();
    });
  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll') onScroll(e: Event): void {
    const header = document.getElementById('header');
    if (window.scrollY > 10) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  }

  toggle() {
    this.opened = !this.opened;
  }

  logout() {
    this.auth.logout().subscribe(() => {
      window.location.reload();
    });
  }

}
