import {Injectable} from '@angular/core';
import {SyossUser} from '../definitions/entities/syoss-user';
import {of} from 'rxjs';
import {UserService} from '@eclick/core';
import {ApCode} from '../definitions/entities/ap-code';
import {GetMyApCodesResponse} from '../definitions/models/get-my-ap-codes.response';
import jsonToFormData from '@ajoelp/json-to-formdata';

@Injectable({
  providedIn: 'root'
})
export class SyossUserService extends UserService<SyossUser>{

  private myApCodes: ApCode[] = [];

  public saveApCodes(codes: ApCode[]) {
    this.myApCodes = codes;
  }

  public getCodes() {
    return of (this.myApCodes);
  }

  public uploadCode(data: any) {
    const formData = jsonToFormData(data);
    const obs = this.api.post<GetMyApCodesResponse>('upload-new-ap-code', formData);
    obs.subscribe((response) => {
      if (response?.data?.ap_codes) {
        this.saveApCodes(response.data.ap_codes);
      }
    });
    return obs;
  }

}
