import { Injectable } from '@angular/core';
import { ApiLanguageService, ApiResponse, ApiService, AuthenticationService, BaseAppInitService } from '@eclick/core';
import { FaqService } from './faq.service';
import { SyossUserService } from './syoss-user.service';
import { DocumentsService } from './documents.service';
import { ShopService } from './shop.service';
import { InitResponse } from '../definitions/models/init-response';
import { Winners } from '../definitions/entities/winners';
import { WinnersService } from './winners.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService extends BaseAppInitService<any> {

  constructor(protected api: ApiService,
              protected auth: AuthenticationService,
              protected apiLanguage: ApiLanguageService,
              private documentsService: DocumentsService,
              private user: SyossUserService,
              private faq: FaqService,
              private shop: ShopService,
              private winners: WinnersService
  ) {
    super(api, auth, apiLanguage);
  }

  processData(response: InitResponse): any {
    const user = response.data.user;
    const winners: Winners = response.data.winners;
    const apCodes = response.data.ap_codes;
    const shops = response.data.active_shops;
    if (user) {
      this.user.setUserData(user).subscribe();
    }

    if (apCodes) {
      this.user.saveApCodes(apCodes);
    }

    if (shops) {
      this.shop.setShops(shops);
    }

    if (winners) {
      this.winners.setWinners(winners);
    }

    this.documentsService.setDocuments(response.data.documents);
    this.faq.setFaqs(response.data.faqs);
  }
}
